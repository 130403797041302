/* eslint-disable no-unused-vars */
import pluralize from "pluralize"
import PropTypes from "prop-types"
import React from "react"
import { useFormContext } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const ApplyToQuotesModal = ({
  isLoading,
  isOpen,
  onClose,
  onSave,
  outstandingCount,
  unsentCount,
}) => {
  const { reset, register } = useFormContext({
    defaultValues: { applyToQuotes: "false" },
  })

  const bodyText = () => {
    if (unsentCount > 0 && outstandingCount > 0) {
      return `${unsentCount} unsent and ${outstandingCount} outstanding contracts in this group?`
    } else if (unsentCount > 0) {
      return `${unsentCount} unsent ${pluralize(
        "contract",
        unsentCount
      )} in this group?`
    } else if (outstandingCount > 0) {
      return `${outstandingCount} outstanding ${pluralize(
        "contract",
        outstandingCount
      )} in this group?`
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header>
        <div className="text-lg font-semibold">Update contract group</div>
      </Modal.Header>
      <Modal.Body>
        <div className="flex flex-col space-y-2 pb-4">
          <span>
            Note: This will only apply to future contracts added to this group
          </span>
        </div>
        {/* TODO: Removing for now, until bug is fixed */}
        {/* <div className="flex flex-col space-y-2 pb-4">
          <span>Would you like to apply these changes to the {bodyText()}</span>
          <div className="space-y-3">
            <Form.RadioButton
              {...register("applyToQuotes")}
              value="false"
              label="No, only apply to future contracts added to this group"
            />
            <Form.RadioButton
              {...register("applyToQuotes")}
              value="true"
              label="Yes, apply changes to existing unsent and outstanding contracts"
            />
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-2 flex flex-row justify-end gap-4">
          <Button variant="secondary" onClick={onClose} disabled={isLoading}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSave} isLoading={isLoading}>
            {isLoading ? "Updating" : "Update"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ApplyToQuotesModal.propTypes = {
  isLoading: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  outstandingCount: PropTypes.number,
  unsentCount: PropTypes.number,
}

export default ApplyToQuotesModal
/* eslint-enable no-unused-vars */
