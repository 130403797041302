import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation, useQuery } from "react-query"
import PricePanel from "src/main/Billing/Items/EditInstallmentStay/shared/PricePanel"
import {
  calculateCancellationFeeTotal,
  centsToDollars,
  dollarsToCents,
  toTaxPercent,
  toTaxRate,
} from "src/main/Billing/Items/helpers"
import { validatePrecision } from "src/main/Billing/Items/validators"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"
import { DangerousDeprecatedOldReactAdapterComponent } from "src/components/__dangerous__"

import { queryProducts } from "src/api/Billing/Items"
import { queryInvoices } from "src/api/Billing/Payments"
import { cancelContract } from "src/api/Contracts"
import { cancelReservation } from "src/api/Reservation"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDateField from "./DueDateField"
import RefundOptionsDropdown from "./RefundOptionsDropdown"
import { useGetRefundablePaymentMethods } from "./hooks"

export const getMultiplePaymentText = (contractQuoteId) =>
  `More than one payment method was used on this ${
    contractQuoteId ? "contract" : "reservation"
  }. To issue a refund, please remove billing products / services manually.`
export const getProcessingInvoicePaymentText = (contractQuoteId) =>
  `This ${
    contractQuoteId ? "contract" : "reservation"
  } cannot be refunded due to an invoice that is still processing.`
export const HAS_DISPUTES_TEXT =
  "Payment(s) are under dispute. To issue a refund, please remove billing products / services manually."
export const NOT_REMOVING_ITEMS_TEXT =
  "By unchecking this box, all paid and unpaid invoices will remain on the contract. Your customer may still receive reminders for upcoming payments if they have unpaid invoices. You can manually remove these items from the Items table if needed."
export const UNSETTLED_REFUND_INVOICE_TEXT =
  "A cancellation fee cannot be applied due to an unsettled refund invoice."

const CancelContractModal = ({
  reservationId,
  contractQuoteId,
  deprecatedButtonTargetId,
  buttonVariant = "secondary",
  hasDisputes = false,
  feeEnabled = false,
  cancellationPolicyPath = null,
}) => {
  const marinaSlug = getCurrentMarinaSlug()
  const MULTIPLE_PAYMENT_TEXT = getMultiplePaymentText(contractQuoteId)
  const PROCESSING_INVOICE_TEXT =
    getProcessingInvoicePaymentText(contractQuoteId)
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    setError,
    clearErrors,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      isRemovingProducts: true,
      cancellationNote: "",
      dueDate: new Date(),
    },
  })

  const [
    cancellationFeeAmount,
    cancellationFeeProductId,
    cancellationFeeTaxRate,
    isRemoving,
  ] = watch([
    "cancellationFeeAmount",
    "cancellationFeeProductId",
    "cancellationFeeTaxRate",
    "isRemovingProducts",
  ])

  // Fetching Payment Methods
  const {
    data: { isMultiplePaymentRequired, refundablePaymentMethods },
    isFetching: isLoadingRefundablePaymentMethods,
    isError: isErrorRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    options: {
      onSuccess: ({ isMultiplePaymentRequired }) => {
        if (isMultiplePaymentRequired || hasDisputes) {
          setValue("isRemovingProducts", false)
        }
      },
      select: (data) => ({
        refundablePaymentMethods: data,
        isMultiplePaymentRequired: data.length > 1,
      }),
      onError: () => {
        setError("root.serverError", {
          message: "Something went wrong! Please contact support.",
        })
      },
      placeholderData: [],
      enabled: isOpen,
    },
  })

  // Fetching invoices
  const {
    isFetching: isLoadingInvoices,
    isError: isErrorInvoices,
    data: invoicesData,
  } = useQuery({
    queryKey: ["invoices", reservationId],
    queryFn: () => queryInvoices({ marinaSlug, reservationId }),
    placeholderData: [],
    enabled: feeEnabled && isOpen,
  })

  // Fetch the marina's products that have a `cancellation_fees` availability
  const {
    isFetching: isLoadingCancellationFeeProducts,
    isError: isErrorCancellationFeeProducts,
    data: cancellationFeeProducts,
  } = useQuery({
    queryKey: ["cancellationFeeProducts", reservationId],
    queryFn: () =>
      queryProducts({
        page: 1,
        marinaSlug,
        availability: ["cancellation_fees"],
      }),
    placeholderData: [],
    enabled: feeEnabled && isOpen,
  })

  const onModalClose = () => {
    setIsOpen(false)
  }

  const hasProcessingInvoice = invoicesData.some(
    (invoice) =>
      invoice.settled_at === null && invoice.display_status === "Processing"
  )
  const unsettledRefundInvoices = invoicesData.filter(
    (invoice) =>
      invoice.total_amount < 0 &&
      invoice.balance < 0 &&
      invoice.settled_at === null
  )
  const hasUnsettledRefundInvoice =
    feeEnabled && unsettledRefundInvoices.length > 0
  const unsettledRefundTotal = unsettledRefundInvoices.reduce(
    (accumulator, invoice) => accumulator - invoice.balance,
    0
  )

  // processing payments as treated as settled with a 0 balance
  const customerPaymentNetAmount = invoicesData.reduce(
    (accumulator, invoice) =>
      accumulator + (invoice.total_amount - invoice.balance),
    0
  )
  const isTransientWithNoPayments =
    !contractQuoteId &&
    invoicesData.every(
      (invoice) =>
        invoice.settled_at === null && invoice.display_status === "Scheduled"
    )

  const cannotIssueRefund =
    isMultiplePaymentRequired || hasDisputes || hasProcessingInvoice
  const feeIsSupported =
    isRemoving &&
    !hasUnsettledRefundInvoice &&
    (isTransientWithNoPayments || customerPaymentNetAmount > 0)

  useEffect(() => {
    if (feeEnabled && invoicesData.length > 0 && hasProcessingInvoice) {
      setValue("isRemovingProducts", false)
    }
  }, [feeEnabled, invoicesData, hasProcessingInvoice, setValue])

  useEffect(() => {
    if (!isRemoving && cancellationFeeProductId) {
      setValue("cancellationFeeProductId", "")
      setValue("cancellationFeeAmount", 0)
      setValue("cancellationFeeTaxRate", 0)
    }
  }, [cancellationFeeProductId, isRemoving, setValue])

  const calculateEffectiveRefundTotal = () => {
    let refundValue = 0
    if (isRemoving && !!cancellationFeeAmount) {
      const feeTotal = calculateCancellationFeeTotal(
        cancellationFeeAmount,
        cancellationFeeTaxRate
      )
      refundValue = customerPaymentNetAmount - feeTotal - unsettledRefundTotal
    } else if (isRemoving) {
      refundValue = customerPaymentNetAmount - unsettledRefundTotal
    }

    return refundValue
  }

  const modalTitle = feeEnabled
    ? `Cancel ${contractQuoteId ? "Contract" : "Reservation"}`
    : "Confirm cancellation"

  // validations
  const validateCancellationFeeAmount = (value) => {
    if (value <= 0) {
      return "Cancellation fee amount must be greater than 0."
    }

    const precisionError = validatePrecision(2)(value)
    if (precisionError) {
      return precisionError
    }

    if (!isTransientWithNoPayments) {
      const feeValue = calculateCancellationFeeTotal(
        value,
        cancellationFeeTaxRate
      )
      const taxRate = cancellationFeeTaxRate
        ? toTaxRate(cancellationFeeTaxRate)
        : 0

      if (taxRate > 0 && feeValue > customerPaymentNetAmount) {
        return "Cancellation fee with tax cannot exceed amount paid by customer."
      } else if (feeValue > customerPaymentNetAmount) {
        return "Cancellation fee amount cannot exceed amount paid by customer."
      }
    }
  }

  const validateCancellationFeeTaxRate = (value) => {
    if (value < 0 || value > 100) {
      return "Cancellation fee tax must be between 0 and 100."
    }

    const precisionError = validatePrecision(4)(value)
    if (precisionError) {
      return precisionError
    }
  }

  const { mutate, isLoading, isSuccess } = useMutation({
    queryKey: ["cancel", reservationId],
    mutationFn: (data) =>
      contractQuoteId
        ? cancelContract(contractQuoteId, data)
        : cancelReservation(reservationId, data),
    onSuccess: ({ redirect_to: redirectTo }) =>
      window.location.assign(new URL(redirectTo, window.location.origin)),
    onError: () => {
      setError("root.serverError", {
        message:
          "There was a problem canceling this contract. Please try again or contact support.",
      })
    },
  })

  const handleCancellationFeeProductSelection = (productId) => {
    clearErrors(["cancellationFeeAmount", "cancellationFeeTaxRate"])
    setValue("cancellationFeeProductId", productId)

    const product = cancellationFeeProducts.find(
      (product) => product.id === productId
    )
    const cancellationFeeAmount = product ? product.price_per_unit : 0
    const cancellationFeeTax = product ? product.tax_rate : 0

    setValue("cancellationFeeAmount", centsToDollars(cancellationFeeAmount))
    setValue("cancellationFeeTaxRate", toTaxPercent(cancellationFeeTax))
  }

  const onSubmit = ({ isRemovingProducts, cancellationNote, dueDate }) => {
    const formattedData = {
      cancellation_note: cancellationNote ? cancellationNote.trim() : null,
      return_product_sale_txns: isRemovingProducts,
      refund_due_date: isRemovingProducts ? dueDate : null,
      payment_method_id: cannotIssueRefund
        ? null
        : refundablePaymentMethods[0]?.payment_method_id,
    }

    if (feeEnabled && feeIsSupported && cancellationFeeProductId) {
      formattedData.cancellation_fee_product_id = cancellationFeeProductId
      formattedData.cancellation_fee_amount = dollarsToCents(
        cancellationFeeAmount
      )
      formattedData.cancellation_fee_tax_rate = toTaxRate(
        cancellationFeeTaxRate
      )
    }

    mutate(formattedData)
  }

  const handleEditLinkClick = () => {
    // reset the product select so that when they return to the modal and
    // the products query refetches fresh data that they can re-select
    // the intended product and have the form populate with updated values
    handleCancellationFeeProductSelection("")
    const editProductPath = `/manage/${marinaSlug}/items/${cancellationFeeProductId}/edit`

    window.open(editProductPath, "_blank", "noreferrer")
  }

  const renderBody = () => {
    const resourcesLoading =
      isLoadingRefundablePaymentMethods ||
      isLoadingInvoices ||
      isLoadingCancellationFeeProducts
    const hasResourceError =
      isErrorRefundablePaymentMethods ||
      isErrorInvoices ||
      isErrorCancellationFeeProducts

    return (
      <ReloadableWidget isLoading={resourcesLoading} isError={hasResourceError}>
        <div className="flex flex-col gap-4">
          {renderCannotIssueRefundHelpText()}
          {renderUnsettledRefundHelpText()}
          {renderCancellationPolicy()}
          {renderPaymentAndRefundAmountsDisplay()}
        </div>
        {feeEnabled && <Divider />}
        <div className="flex flex-col gap-4">
          {renderCancellationFeeProductSelect()}
          {renderCancellationFeeInputs()}
          <div>
            <Form.Label htmlFor="cancellation-note">
              {feeEnabled ? "Cancellation note" : "Cancellation Note"}
            </Form.Label>
            <Form.TextField
              id="cancellation-note"
              {...register("cancellationNote")}
            />
          </div>
        </div>
        {feeEnabled ? <Divider /> : <div className="my-4" />}
        <div className="flex flex-col gap-4">
          {renderRemoveItemsCheckbox()}
          {renderNotRemovingItemsHelpText()}
          {renderRefundInputs()}
        </div>
      </ReloadableWidget>
    )
  }

  const renderCancelBtn = () => {
    const btn = (
      <Button variant={buttonVariant} onClick={() => setIsOpen(true)}>
        Cancel {contractQuoteId ? "Contract" : "Reservation"}
      </Button>
    )

    return deprecatedButtonTargetId ? (
      <DangerousDeprecatedOldReactAdapterComponent
        targetId={deprecatedButtonTargetId}
        weAcknowledgeThisTechniqueIsDangerous
      >
        {btn}
      </DangerousDeprecatedOldReactAdapterComponent>
    ) : (
      btn
    )
  }

  const renderCancellationPolicy = () => {
    if (!feeEnabled || contractQuoteId || !cancellationPolicyPath) {
      return null
    }

    return (
      <div className="mb-4">
        You can view the terms of the{" "}
        <a
          href={cancellationPolicyPath}
          className="text-link font-semibold no-underline"
          target="_blank"
          rel="noreferrer"
        >
          cancellation agreement
        </a>{" "}
        here. Apply an optional cancellation fee and/or schedule a refund.
      </div>
    )
  }

  const renderRemoveItemsCheckbox = () => {
    const unpaidOrFullyRefunded = customerPaymentNetAmount === 0
    const unsettledRefundWouldZeroOutAllPayments =
      hasUnsettledRefundInvoice &&
      unsettledRefundTotal === customerPaymentNetAmount
    const noBalanceToRefund =
      unpaidOrFullyRefunded || unsettledRefundWouldZeroOutAllPayments

    const updatedRemoveItemsCheckboxText = noBalanceToRefund
      ? "Remove unpaid invoices"
      : "Schedule a refund and remove unpaid invoices"
    const removeItemsCheckboxText = feeEnabled
      ? updatedRemoveItemsCheckboxText
      : "Remove all billing products / services"

    return (
      <div>
        <Form.Checkbox
          {...register("isRemovingProducts")}
          label={removeItemsCheckboxText}
          disabled={cannotIssueRefund}
        />
      </div>
    )
  }

  const renderNotRemovingItemsHelpText = () => {
    if (!feeEnabled || isRemoving || cannotIssueRefund) {
      return null
    }
    return (
      <div className="flex flex-row items-start space-x-2">
        <i className="icon icon-info-circle text-lg text-yellow-700" />
        <span>{NOT_REMOVING_ITEMS_TEXT}</span>
      </div>
    )
  }

  const renderPaymentAndRefundAmountsDisplay = () => {
    if (!feeEnabled) {
      return null
    }

    const customerPaidDisplay = formattedCentsToDollars(
      customerPaymentNetAmount
    )
    const effectiveRefundTotal = calculateEffectiveRefundTotal()
    const chargingFeeOnUnpaidTransient =
      isTransientWithNoPayments && cancellationFeeProductId
    const zeroRefundBeforeFee =
      effectiveRefundTotal === 0 && !cancellationFeeProductId

    const refundTotalDisplay =
      zeroRefundBeforeFee || chargingFeeOnUnpaidTransient
        ? "-"
        : formattedCentsToDollars(effectiveRefundTotal)

    return (
      <ReloadableWidget
        isLoading={isLoadingInvoices}
        isError={isErrorInvoices}
        showOverlay
      >
        <div
          className="flex flex-row justify-around gap-8 px-12"
          data-testid="payment-and-refund-display"
        >
          <PricePanel
            title="Customer Paid"
            value={
              <span className="text-2xl font-bold">{customerPaidDisplay}</span>
            }
          />
          <PricePanel
            title="Refund Total"
            value={
              <span
                className={classNames("text-2xl font-bold", {
                  "text-red-700":
                    !chargingFeeOnUnpaidTransient && effectiveRefundTotal < 0,
                })}
              >
                {refundTotalDisplay}
              </span>
            }
          />
        </div>
      </ReloadableWidget>
    )
  }

  const renderCancellationFeeProductSelect = () => {
    if (!feeEnabled) {
      return null
    }

    return (
      <div>
        <div className="flex justify-between">
          <Form.Label htmlFor="cancellation-fee-product">
            Apply a cancellation fee
          </Form.Label>
          {cancellationFeeProductId ? (
            <span
              className="text-link font-semibold"
              onClick={handleEditLinkClick}
            >
              Edit fee
            </span>
          ) : null}
        </div>
        <Form.Select
          id="cancellation-fee-product"
          {...register("cancellationFeeProductId")}
          onChange={(event) =>
            handleCancellationFeeProductSelection(event.target.value)
          }
          disabled={!feeIsSupported}
        >
          <option key={""} value={""}>
            None
          </option>
          {cancellationFeeProducts.map((product) => (
            <option key={product.id} value={product.id}>
              {product.name}
            </option>
          ))}
        </Form.Select>
      </div>
    )
  }

  const renderCancellationFeeInputs = () => {
    if (!feeEnabled || !cancellationFeeProductId) {
      return null
    }

    return (
      <div className="flex flex-row gap-4">
        <div className="w-1/2">
          <Form.Label htmlFor="cancellation-fee-amount">
            Cancellation fee amount
          </Form.Label>
          <Form.IconTextField
            id="cancellation-fee-amount"
            {...register("cancellationFeeAmount", {
              required: "Cancellation fee amount is required.",
              valueAsNumber: true,
              validate: validateCancellationFeeAmount,
            })}
            icon="$"
            position="left"
            inputMode="numeric"
            type="number"
            hasErrors={!!errors.cancellationFeeAmount}
          />
          {errors?.cancellationFeeAmount ? (
            <Form.Error>{errors.cancellationFeeAmount.message}</Form.Error>
          ) : null}
        </div>
        <div className="w-1/2">
          <Form.Label htmlFor="cancellation-fee-tax-rate">
            Cancellation fee tax
          </Form.Label>
          <Form.IconTextField
            id="cancellation-fee-tax-rate"
            {...register("cancellationFeeTaxRate", {
              required: "Cancellation fee tax is required.",
              valueAsNumber: true,
              validate: validateCancellationFeeTaxRate,
            })}
            icon="%"
            position="right"
            inputMode="numeric"
            type="number"
            hasErrors={!!errors.cancellationFeeTaxRate}
          />
          {errors?.cancellationFeeTaxRate ? (
            <Form.Error>{errors.cancellationFeeTaxRate.message}</Form.Error>
          ) : null}
        </div>
      </div>
    )
  }

  const renderCannotIssueRefundHelpText = () => {
    if (!cannotIssueRefund) {
      return null
    }

    if (hasDisputes) {
      return <AlertBanner type="error">{HAS_DISPUTES_TEXT}</AlertBanner>
    }

    if (isMultiplePaymentRequired) {
      return <AlertBanner type="error">{MULTIPLE_PAYMENT_TEXT}</AlertBanner>
    }

    if (hasProcessingInvoice) {
      return <AlertBanner type="error">{PROCESSING_INVOICE_TEXT}</AlertBanner>
    }
  }

  const renderUnsettledRefundHelpText = () => {
    if (!feeEnabled || !hasUnsettledRefundInvoice) {
      return null
    }

    return (
      <AlertBanner type="error">{UNSETTLED_REFUND_INVOICE_TEXT}</AlertBanner>
    )
  }

  const renderRefundInputs = () => {
    const applyingFeeToUnpaidTransient =
      feeEnabled && isTransientWithNoPayments && cancellationFeeProductId
    const showAdditionalRefundInputs =
      !cannotIssueRefund &&
      isRemoving &&
      (refundablePaymentMethods.length === 1 || applyingFeeToUnpaidTransient)

    if (!showAdditionalRefundInputs) {
      return null
    }

    return (
      <div className="flex flex-row gap-4">
        <div className="w-1/2">
          <RefundOptionsDropdown
            name="refundOption"
            control={control}
            disabled
          />
        </div>
        <div className="w-1/2" data-testid="due-date">
          <DueDateField
            required
            name="dueDate"
            control={control}
            error={errors?.dueDate}
          />
        </div>
      </div>
    )
  }

  const submitDisabled =
    isLoadingCancellationFeeProducts ||
    isLoadingInvoices ||
    isLoadingRefundablePaymentMethods ||
    isLoading ||
    isSuccess

  return (
    <>
      {renderCancelBtn()}
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        afterLeave={reset}
        size="mediumFixed"
      >
        <Modal.Header title={modalTitle} />
        <Modal.Body>
          <Form autocomplete={false}>{renderBody()}</Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex justify-end space-x-2">
              <Button variant="tertiary" onClick={onModalClose}>
                {feeEnabled ? "Back" : "Cancel"}
              </Button>
              <Button
                type="submit"
                variant="danger"
                isLoading={isLoading || isSuccess}
                disabled={submitDisabled}
                onClick={handleSubmit(onSubmit)}
              >
                Cancel {contractQuoteId ? "Contract" : "Reservation"}
              </Button>
            </div>
            {errors?.root?.serverError ? (
              <Form.Error>{errors.root.serverError.message}</Form.Error>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

CancelContractModal.propTypes = {
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  hasDisputes: PropTypes.bool,
  buttonVariant: PropTypes.string,
  deprecatedButtonTargetId: PropTypes.string,
  feeEnabled: PropTypes.bool,
  cancellationPolicyPath: PropTypes.string,
}

export default CancelContractModal
