import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

import displayDuration from "./displayDuration"

const TravelPlans = ({ fromCruiseStopName, register, errors, watch }) => {
  return (
    <>
      <h2 className="mb-2 text-base font-bold">
        Travel Plans{" "}
        <span className="text-sm font-normal text-gray-600">(optional)</span>
      </h2>
      <p className="text-sm text-gray-600">
        Travel plans let you add information about how and when you are getting
        to this stop from the previous stop.
      </p>
      <div className="space-y-4">
        <div>
          <Form.Label htmlFor="fromCruiseStopId">Arriving from</Form.Label>
          <Form.TextField
            id="fromCruiseStopId"
            value={fromCruiseStopName || null}
            disabled
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div>
            <Form.Label htmlFor="nauticalMiles">
              Nautical Miles <span className="font-normal">(distance)</span>
            </Form.Label>
            <Form.TextField
              id="nauticalMiles"
              {...register(`nauticalMiles`)}
              hasErrors={!!errors?.nauticalMiles}
            />
            {errors?.nauticalMiles && (
              <Form.Error>{errors?.nauticalMiles?.message}</Form.Error>
            )}
          </div>

          <div>
            <Form.Label htmlFor="knots">
              Knots <span className="font-normal">(speed)</span>
            </Form.Label>
            <Form.TextField
              id="knots"
              {...register(`knots`)}
              hasErrors={!!errors?.knots}
            />
            {errors?.knots && <Form.Error>{errors?.knots?.message}</Form.Error>}
          </div>
        </div>
        {watch("nauticalMiles") && watch("knots") && (
          <div>
            <Form.Label htmlFor="duration">Duration</Form.Label>
            <Form.TextField
              id="duration"
              value={displayDuration(watch("nauticalMiles") / watch("knots"))}
              disabled
            />
          </div>
        )}
      </div>
    </>
  )
}

TravelPlans.propTypes = {
  fromCruiseStopName: PropTypes.string,
  errors: PropTypes.shape({
    nauticalMiles: PropTypes.string,
    knots: PropTypes.string,
  }),
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
}

export default TravelPlans
