import { compareDesc, isDate } from "date-fns"
import format from "date-fns/format"
import Decimal from "decimal.js"
import { ITEM_PRICE_PER_UNIT_PRECISION } from "src/main/Billing/constants"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { PERCENT_OF_STORAGE_PRICING_STRUCTURE } from "../constants"
import { groupByRecurringProductSaleId, parseDateValue } from "../helpers"

export const formatDate = (date) => {
  const parsedDate = isDate(date) ? date : parseDateValue(date)

  return format(parsedDate, "MM/dd/yyyy")
}

export const itemDatesAndSubtext = ({
  startDate,
  endDate,
  monthToMonth,
  departureDate,
}) => {
  const formattedStartDate = startDate && formatDate(startDate)
  const formattedEndDate = endDate && formatDate(endDate)

  let dates, datesSubtext

  if (monthToMonth) {
    dates = "Month-to-Month"

    if (endDate) {
      datesSubtext = `Ending ${formattedEndDate}`
    }
  } else {
    dates =
      formattedStartDate === formattedEndDate
        ? formattedEndDate
        : `${formattedStartDate} to ${formattedEndDate}`
    datesSubtext = departureDate
      ? `Departure: ${formatDate(departureDate)}`
      : undefined
  }

  return [dates, datesSubtext]
}

export const getDueDate = ({ paymentSchedule, customDueDate }) => {
  switch (paymentSchedule) {
    case "end_of_day":
      return new Date()
    case "custom_due_date":
      return customDueDate
    case "unscheduled":
    case "due_on_signature":
    case "add_to_installments":
      return null
    case "add_to_next_payment":
      return "next"
    case "reservation_check_in":
      return "reservation_check_in"
    case "reservation_check_out":
      return "reservation_check_out"
  }
}

export const cardDescription = ({ card, condensed = false }) =>
  card.title
    ? currentCardDescription({ card, condensed })
    : legacyCardDescription({ card, condensed })

const currentCardDescription = ({ card, condensed = false }) =>
  condensed ? card.title : `${card.title} ${card.subtitle}`

// Copied from app/assets/javascripts/components/helpers.es6.jsx
const legacyCardDescription = ({ card, condensed = false }) => {
  let cardStr = `${card.brand} ****${card.last4}`

  if (!condensed) {
    const dateStr = `${card.exp_month}/${card.exp_year.toString().substr(2, 2)}`
    const expiryStr = card.is_expired ? "expired" : "expires"

    cardStr = cardStr + ` ${expiryStr} ${dateStr}`
  }

  return cardStr
}

export const calculateQuantityIncludingMeteredElectric = (data) => {
  const lastMeterReading = data.metered_electric?.last_meter_reading
  const currentMeterReading = data.metered_electric?.current_meter_reading
  const pricingStructure = data.txn.product_sale_attributes.pricing_structure

  if (lastMeterReading && currentMeterReading) {
    return parseFloat(
      (
        new Decimal(currentMeterReading) - new Decimal(lastMeterReading)
      ).toFixed(6)
    )
  } else if (pricingStructure === PERCENT_OF_STORAGE_PRICING_STRUCTURE) {
    return toPercentBasedRate(data.txn.product_sale_attributes.quantity)
  }
  return data.txn.product_sale_attributes.quantity
}

export const getOriginalAmount = ({ quantity, pricePerUnit }) => {
  const originalAmountString = new Decimal(quantity)
    .mul(new Decimal(pricePerUnit))
    .toFixed(2)

  return parseFloat(originalAmountString)
}

export const getTaxAmount = ({ originalAmount, taxPercent }) => {
  const taxRate = toTaxRate(taxPercent)
  const taxAmountString = new Decimal(originalAmount)
    .mul(new Decimal(taxRate))
    .toFixed(2)

  return parseFloat(taxAmountString)
}

export const getAmount = ({ originalAmount, taxAmount }) =>
  originalAmount + taxAmount

export const toTaxPercent = (taxRate) =>
  parseFloat(new Decimal(taxRate).mul(100).toFixed(4))

export const toTaxRate = (taxPercent) =>
  parseFloat(new Decimal(taxPercent).div(100).toFixed(6))

export const toPercentBasedRate = (percentRate) =>
  parseFloat(new Decimal(percentRate).div(100).toFixed(4))

export const dollarsToCents = (dollars) =>
  parseFloat(new Decimal(dollars).mul(100).toFixed(0))

export const dollarsToHundredthsOfCents = (dollars) =>
  parseFloat(new Decimal(dollars).mul(10000).toFixed(0))

export const centsToDollars = (cents) => new Decimal(cents).div(100).toNumber()

export const hundredthsOfCentsToDollars = (hundredthsOfCents) =>
  new Decimal(hundredthsOfCents).div(10000).toNumber()

export const pricePerUnitToDollars = ({ pricePerUnit, precision }) => {
  switch (precision) {
    case "cents":
      return centsToDollars(pricePerUnit)
    case "hundredths_of_cents":
      return hundredthsOfCentsToDollars(pricePerUnit)
    default:
      throw new Error(`Unknown precision: ${precision}`)
  }
}

export const dollarsToPricePerUnit = ({ dollars, precision }) => {
  switch (precision) {
    case "cents":
      return dollarsToCents(dollars)
    case "hundredths_of_cents":
      return dollarsToHundredthsOfCents(dollars)
    default:
      throw new Error(`Unknown precision: ${precision}`)
  }
}

export const getPriceAndTaxAmounts = (
  data,
  calculatedQuantity,
  reservationSale
) => {
  const {
    txn: {
      product_sale_attributes: {
        price_per_unit: pricePerUnitInDollarsString,
        price_precision: pricePrecision,
        tax_percent: taxPercent,
        pricing_structure: pricingStructure,
      },
    },
  } = data
  const isPercentOfReservationSale =
    pricingStructure === PERCENT_OF_STORAGE_PRICING_STRUCTURE
  let pricePerUnitInDollars

  if (isPercentOfReservationSale && !reservationSale) {
    pricePerUnitInDollars = 0
  } else if (isPercentOfReservationSale && reservationSale) {
    const reservationSalePreTaxAmount =
      reservationSale.product_sale.original_amount -
      reservationSale.product_sale.discount_amount
    pricePerUnitInDollars = new Decimal(reservationSalePreTaxAmount).div(100)
  } else {
    pricePerUnitInDollars = parseFloat(pricePerUnitInDollarsString)
  }
  const quantity =
    calculatedQuantity ?? calculateQuantityIncludingMeteredElectric(data)
  const originalAmount = getOriginalAmount({
    quantity,
    pricePerUnit: pricePerUnitInDollars,
  })
  const taxAmount = getTaxAmount({ originalAmount, taxPercent })
  const amount = getAmount({ originalAmount, taxAmount })
  const pricePerUnit = dollarsToPricePerUnit({
    dollars: pricePerUnitInDollars,
    precision: isPercentOfReservationSale ? "cents" : pricePrecision,
  })
  const taxRate = toTaxRate(taxPercent)

  return {
    originalAmount: dollarsToCents(originalAmount),
    taxAmount: dollarsToCents(taxAmount),
    amount: dollarsToCents(amount),
    pricePerUnit,
    taxRate,
  }
}

export const getItemsForStorageRecurringProductSale = (items) => {
  const groupedData = items.reduce(groupByRecurringProductSaleId, {})
  const productSaleTxns = Object.entries(groupedData)

  const storageRecurringProductSales = productSaleTxns.find(
    ([rpsItemId, rpsItems]) =>
      rpsItemId !== "null" && // if there's no id as the first array element, it's not recurring
      rpsItems[0].product_sale.recurring_product_sale.reservation_sale
  )

  return storageRecurringProductSales && storageRecurringProductSales[1]
}

export const discountDisplayText = ({
  name,
  amount,
  percent_amount: percentAmount,
}) =>
  percentAmount
    ? `${name} ${new Decimal(percentAmount).mul(100).toNumber()}%`
    : `${name} ${formattedCentsToDollars(amount)}`

export const taxAmountWithPercentDisplayText = ({ taxRate, taxAmount }) => {
  const taxRatePercent = new Decimal(taxRate ?? 0).mul(100).toNumber()

  return `${formattedCentsToDollars(taxAmount)} (${taxRatePercent}%)`
}

export const getReservationLinkedItems = (items) => {
  return items.filter(
    (item) =>
      item.product_sale.reservation_dates && !item.product_sale.reservation_sale
  )
}

export const sortByLatestPostedAt = (a, b) =>
  compareDesc(new Date(a.posted_at), new Date(b.posted_at))

export const getEffectiveAmount = ({ aggregatedAmount, amount }) => {
  return aggregatedAmount ?? amount ?? 0
}

export const getEffectiveBalance = ({ aggregatedBalance, balance }) => {
  return aggregatedBalance ?? balance ?? 0
}

export const collapseRows = (accum, item, existingSaleIndex) => {
  const existingSale = accum[existingSaleIndex]
  const [latestChildTxn] = item.child_txns.sort(sortByLatestPostedAt)
  let aggregatedAmount = getEffectiveAmount(existingSale) + item.amount
  let aggregatedBalance = getEffectiveBalance(existingSale) + item.balance

  // if an item has a child txn associated we need to include those amounts as well
  if (latestChildTxn) {
    aggregatedAmount += latestChildTxn.amount
    aggregatedBalance += latestChildTxn.balance ?? 0
  }

  accum[existingSaleIndex] = {
    ...existingSale,
    aggregatedAmount,
    aggregatedBalance,
  }
  return accum
}

// we want to really only render one item to represent the reservation sale,
// which means aggregating the balances/amounts of the other reservation sale
// items and adding it to the sale we're going to display
export const collapseReservationSalesAndDates = (items) => {
  if (!items?.length) {
    return
  }
  // we're sorting so we will encounter the newest reservation sale
  // to use as our displayed item as we iterate over this array
  return items.sort(sortByLatestPostedAt).reduce((accum, item) => {
    if (
      !item.product_sale.reservation_sale &&
      !item.product_sale.reservation_dates
    ) {
      return [...accum, item]
    }

    let existingSaleIndex = -1
    if (item.product_sale.reservation_sale) {
      existingSaleIndex = accum.findIndex(
        (item) => item.product_sale.reservation_sale
      )
    } else {
      existingSaleIndex = accum.findIndex(
        (existingItem) =>
          existingItem.product_sale.product_id ===
            item.product_sale.product_id &&
          existingItem.product_sale.reservation_dates
      )
    }

    if (existingSaleIndex < 0) {
      return [...accum, item]
    }

    return collapseRows(accum, item, existingSaleIndex)
  }, [])
}

export const getPricePerUnitPrecision = (pricePrecision) => {
  const precision = ITEM_PRICE_PER_UNIT_PRECISION[pricePrecision]
  if (!precision) {
    return 2
  }
  return precision
}

export const parseSavedRecurringDiscount = (discount) => {
  const {
    id,
    name,
    percent_amount: percentAmount,
    amount,
    discount_type: discountType,
    discount_start_month: discountStartMonth,
    discount_end_month: discountEndMonth,
    discount_month: discountMonth,
    apply_order: applyOrder,
  } = discount
  const baseAttrs = {
    id,
    name,
    discountType,
    discountMonth,
    discountStartMonth,
    discountEndMonth,
    applyOrder,
  }
  if (percentAmount) {
    return {
      percentage: percentAmount,
      ...baseAttrs,
    }
  }
  return {
    amount: amount,
    ...baseAttrs,
  }
}

export const itemIsReturned = (item) =>
  item.child_txns.some((txn) => txn.type === "Billing::ProductReturnTxn")

export const calculateCancellationFeeTotal = (feeValue, feeTaxRate) => {
  const amountAsCents = dollarsToCents(feeValue)
  let total

  if (feeTaxRate) {
    const taxRate = toTaxRate(feeTaxRate)
    const taxAmountInCents = Math.round(amountAsCents * taxRate)
    total = amountAsCents + taxAmountInCents
  } else {
    total = amountAsCents
  }
  return total
}
