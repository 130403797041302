import { format, isBefore, parseISO, startOfToday } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import DataTable from "src/components/DataTable"
import SidePanel from "src/components/SidePanel"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { occupancyToColor } from "../utils"

const today = startOfToday()

const CalendarDayPanel = ({
  data,
  isOpen,
  onClose,
  getPrevDay,
  getNextDay,
  ratesV2Enabled,
}) => {
  const { day, dayData } = data

  const renderDateSelector = () => {
    return (
      <div className="mb-10 flex w-11/12 justify-between lg:w-min lg:min-w-[350px]">
        <Button
          icon="icon-md-arrow-back -mt-0.5"
          onClick={getPrevDay}
          small
          iconOnly
        />
        <span className="text-nowrap text-lg font-bold">
          {format(parseISO(day), "EEEE, MMMM dd, yyyy")}
        </span>
        <Button
          icon="icon-md-arrow-forward -mt-0.5"
          onClick={getNextDay}
          small
          iconOnly
        />
      </div>
    )
  }

  const renderRecommendations = () => {
    return (
      <AlertBanner
        type="recommendation"
        title="Recommendation"
        cta={{
          text: "Add Deal",
          type: "primary",
          onClick: () => {
            window.open(dayData.recommendedDealCtaLink, "_blank")
          },
        }}
      >
        This day has lower occupancy than expected. Try adding a Dockwa Deal to
        promote your marina to more boaters.
      </AlertBanner>
    )
  }

  const renderRatesTable = () => {
    if (!ratesV2Enabled) {
      return (
        <p className="text-muted">
          Migrate to our new rate management experience to view rates for this
          day.
          <br />
          Contact us to learn more.
        </p>
      )
    }
    if (!dayData.rates) {
      return (
        <p
          className={`${
            isBefore(parseISO(day), today) ? "text-gray-600" : "text-red-700"
          } font-bold`}
        >
          No rates available for this day
        </p>
      )
    }
    const rates = dayData.rates.map((rate) => {
      return {
        loaGroup: `${Math.round(rate.minLength / 12)}' - ${Math.round(
          rate.maxLength / 12
        )}'`,
        price: `${formattedCentsToDollars(rate.rate)}${
          dayData.rateType === "by_length" ? "/ft" : ""
        }`,
      }
    })
    return (
      <DataTable
        rowData={rates}
        colDefs={[
          { key: "loaGroup", header: "LOA Group" },
          { key: "price", header: "Price" },
        ]}
        hideSettings
      />
    )
  }

  const totalLinearFeet = () => {
    if (dayData && dayData.occupancyUnits === "total_linear_feet") {
      return dayData.totalCount
    } else {
      return (
        Math.round(
          dayData.linearInchesOccupied / dayData.occupancyPercentage / 12
        ) || 0
      )
    }
  }

  const totalCount = () => {
    if (dayData && dayData.occupancyUnits === "total_count") {
      return dayData.totalCount
    } else {
      return (
        Math.round(dayData.countOccupied / dayData.occupancyPercentage) || 0
      )
    }
  }

  return (
    <SidePanel isOpen={isOpen} onClose={onClose} width={"5/12"}>
      {renderDateSelector()}
      {data && dayData && (
        <>
          <div className="mb-10 mt-4">
            <div className="grid grid-cols-3 gap-4">
              <div className="flex h-full w-full flex-col">
                <div
                  className={`w-full grow rounded ${occupancyToColor(
                    dayData.occupancyPercentage
                  )} mb-1 px-6 pb-2 pt-4 text-left font-semibold`}
                >
                  <p className="mb-1 text-xs lg:text-sm">Occupancy</p>
                  <p className="mb-0 text-4xl leading-none lg:text-[42px]">
                    {Math.round(dayData.occupancyPercentage * 100)}%
                  </p>
                </div>
                <div
                  className={`w-full rounded py-0.5 text-center align-middle text-xs font-semibold ${
                    Math.round(dayData.yoyOccupancyPercentage * 100) > 0
                      ? "bg-teal-50 text-teal-600"
                      : Math.round(dayData.yoyOccupancyPercentage * 100) === 0
                      ? "bg-gray-200 text-gray-600"
                      : "bg-red-50 text-red-600"
                  }`}
                >
                  {Math.round(dayData.yoyOccupancyPercentage * 100) !== 0 && (
                    <i
                      className={`icon ${
                        Math.round(dayData.yoyOccupancyPercentage * 100) > 0
                          ? "icon-arrow-outward"
                          : "icon-arrow-inward"
                      }`}
                    />
                  )}
                  {Math.round(Math.abs(dayData.yoyOccupancyPercentage) * 100)}%
                  YoY
                </div>
              </div>
              <div
                className={`mb-1 h-full w-full rounded border border-gray-300 bg-white px-6 pb-2 pt-[15px] text-left font-semibold`}
              >
                <p className="mb-1 text-xs lg:text-sm">Dockage</p>
                <p className="mb-0 text-4xl leading-none lg:text-[42px]">
                  {dayData.countOccupied}
                </p>
                <p className="mb-0 mt-1 text-xs font-normal text-gray-600 lg:text-sm">
                  booked of {totalCount()} total
                </p>
              </div>
              <div
                className={`mb-1 h-full w-full rounded border border-gray-300 bg-white px-6 pb-2 pt-[15px] text-left font-semibold`}
              >
                <p className="mb-1 text-xs lg:text-sm">Linear Feet</p>
                <p className="mb-0 text-4xl leading-none lg:text-[42px]">
                  {Math.round(dayData.linearInchesOccupied / 12)}
                </p>
                <p className="mb-0 mt-1 text-xs font-normal text-gray-600 lg:text-sm">
                  booked of {totalLinearFeet()} total
                </p>
              </div>
            </div>
          </div>
          {dayData.recommendedDealCount > 0 && renderRecommendations()}
          <div className="my-10">
            <h3 className="text-lg font-bold">Nightly Rates</h3>
            {renderRatesTable()}
          </div>
        </>
      )}
    </SidePanel>
  )
}

export default CalendarDayPanel

CalendarDayPanel.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  getPrevDay: PropTypes.func.isRequired,
  getNextDay: PropTypes.func.isRequired,
  ratesV2Enabled: PropTypes.bool.isRequired,
}
