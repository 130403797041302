import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import Button from "src/components/Button"
import Form from "src/components/Form"

import { createStop, updateStop } from "src/api/CruisePlan/mutateStop"

import { MAPBOX_TOKEN } from "src/utils/env"

import DateTimeInput, { dateToLoad, dateToSave } from "./DateTimeInput"
import LocationStopAutocomplete from "./LocationStopAutocomplete"
import TravelPlans from "./TravelPlans"

const LocationStopForm = ({ cruiseStop }) => {
  const cruisePlan = cruiseStop.cruisePlan
  const locationStruct = (name = null, latitude = null, longitude = null) => {
    return { name, latitude, longitude }
  }
  const [location, setLocation] = useState(
    locationStruct(cruiseStop.name, cruiseStop.latitude, cruiseStop.longitude)
  )

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      note: cruiseStop.note,
      startDate: cruiseStop.startDate ? dateToLoad(cruiseStop.startDate) : null,
      endDate: cruiseStop.endDate ? dateToLoad(cruiseStop.endDate) : null,
      nauticalMiles: cruiseStop.nauticalMiles,
      knots: cruiseStop.knots,
    },
  })

  const { mutate } = useMutation({
    mutationFn: (data) => {
      // merge location into data
      data = { ...data, ...location }
      data.start_date = data.startDate ? dateToSave(data.startDate) : null
      data.end_date = data.endDate ? dateToSave(data.endDate) : null
      data.nautical_miles = data.nauticalMiles
      if (cruiseStop.id) {
        return updateStop({
          type: cruiseStop.type,
          cruisePlanId: cruisePlan.id,
          id: cruiseStop.id,
          data: data,
        })
      } else {
        return createStop({
          type: cruiseStop.type,
          cruisePlanId: cruisePlan.id,
          data: data,
        })
      }
    },
    onSuccess: (reponse) => {
      window.location.href = reponse.redirect_url
    },
    onError: (reponse) => {
      alert(reponse.errors)
    },
  })

  const onSubmit = (data) => {
    mutate(data)
  }

  const renderLocation = () => {
    // if location is null or an empty object values
    if (!location || Object.values(location).every((value) => !value)) {
      return (
        <LocationStopAutocomplete
          location={location}
          setLocation={setLocation}
          locationStruct={locationStruct}
          cruisePlanId={cruisePlan.id}
        />
      )
    } else {
      return (
        <div className="relative mb-4 flex gap-4 rounded border p-4">
          <span
            className="absolute right-2 top-0 cursor-pointer p-2"
            type="button"
            onClick={() => setLocation(locationStruct())}
          >
            x
          </span>
          <a
            href={`/search?lat=${location.latitude}&lon=${location.longitude}&zoom=12`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="size-24 rounded"
              src={`https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-m-ferry+FE2E2E(${location.longitude},${location.latitude})/${location.longitude},${location.latitude},12,0,0/200x200@2x?access_token=${MAPBOX_TOKEN}`}
            />
          </a>
          <div>
            <h2 className="m-0 text-lg font-semibold">{location.name}</h2>
            <span className="mt-2">
              Latitude: {location.latitude}, Longitude: {location.longitude}
            </span>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="container max-w-5xl">
      <a href={`/account/cruise_plans/${cruisePlan.id}`} className="text-link">
        ← Back to cruise plan
      </a>
      <div className="card mt-4">
        <h1 className="my-0 text-xl font-bold">Add a Location</h1>
        <p className="mb-4">
          Select a city, location, or harbor to stop along your cruise.
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            {renderLocation()}

            <DateTimeInput
              title="Arriving at"
              name="startDate"
              control={control}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            <DateTimeInput
              title="Departing at"
              name="endDate"
              control={control}
              watch={watch}
              setValue={setValue}
              errors={errors}
            />

            <div>
              <Form.Label htmlFor="note">Note</Form.Label>
              <Form.Textarea
                id="note"
                {...register(`note`)}
                hasErrors={!!errors?.note}
              />
              {errors?.note && <Form.Error>{errors?.note?.message}</Form.Error>}
            </div>
          </div>

          <hr />

          <TravelPlans
            fromCruiseStopName={cruiseStop.fromCruiseStopName}
            register={register}
            errors={errors}
            watch={watch}
          />

          <div className="mt-4">
            <Button variant="primary" type="submit">
              {cruiseStop.id ? "Update Location" : "Add Location"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

LocationStopForm.propTypes = {
  cruiseStop: PropTypes.shape({
    id: PropTypes.string,
    cruisePlan: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    name: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    note: PropTypes.string,
    startDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    nauticalMiles: PropTypes.number,
    knots: PropTypes.number,
    fromCruiseStopName: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
}

export default LocationStopForm
