import { endOfYear, format, max, min, startOfYear } from "date-fns"
import Cookies from "js-cookie"

import { getQueryParam } from "src/utils/UrlHelpers"

import { removeCookieAndParam, storeCookieAndParam } from "./InsightHelpers"
import {
  convertYearToEndDate,
  convertYearToStartDate,
  today,
} from "./InsightTimeHelpers"

export default class TimeFrameManager {
  constructor(
    graphSupportedEndDate,
    timeFrameId = null,
    start = null,
    end = null
  ) {
    const initialize = () => {
      this.graphSupportedEndDate = graphSupportedEndDate
      this.minDate = convertYearToStartDate(2020)
      this.maxDate = maxDate()
      this.yearsArray = yearsArray()
      this.shownYears = this.yearsArray.slice(-4)
      this.extraYears = this.yearsArray.slice(0, -4)
      // if start is set past end, set end to start
      end = max([start, end])
      this.timeFrame = timeFrameId ? convert(timeFrameId, start, end) : load()
      this.timeFrameId = this.timeFrame.timeFrameId
      this.start = this.timeFrame.start
      this.end = this.timeFrame.end
      store()
    }

    // ------------- Private Methods -------------//
    const maxDate = () => {
      let maxDateForGraph = null
      switch (this.graphSupportedEndDate) {
        case "future":
          maxDateForGraph = endOfYear(today())
          break
        case "today":
        default:
          maxDateForGraph = today()
      }
      return maxDateForGraph
    }

    const yearsArray = () => {
      const minYear = this.minDate.getFullYear()
      const maxYear = this.maxDate.getFullYear()
      return Array.from({ length: maxYear - minYear + 1 }, (_, index) =>
        (minYear + index).toString()
      )
      // Builds an array that looks like this: ['2020', '2021', '2022', '2023', '2024']
    }

    const defaultTimeFrame = () => {
      return {
        timeFrameId: today().getFullYear().toString(),
        start: boundedDate(startOfYear(today())),
        end: boundedDate(endOfYear(today())),
      }
    }

    const boundedDate = (date) => {
      return max([this.minDate, min([this.maxDate, date])])
    }

    const load = () => {
      // is timeFrame in the url params
      if (getQueryParam("timeFrame")) {
        return parse(getQueryParam)
        // is timeFrame stored as a cookie
      } else if (Cookies.get("insights:timeFrame")) {
        return parse(Cookies.get, "insights:")
        // else set the default timeFrame
      } else {
        return defaultTimeFrame()
      }
    }

    const parse = (parserFunction, prefix = "") => {
      const timeFrameId = parserFunction(`${prefix}timeFrame`)
      if (timeFrameId === "Custom") {
        return {
          timeFrameId: "Custom",
          start: boundedDate(new Date(parserFunction(`${prefix}startDate`))),
          end: boundedDate(new Date(parserFunction(`${prefix}endDate`))),
        }
      } else {
        return convert(timeFrameId)
      }
    }

    const convert = (timeFrameId, start, end) => {
      if (timeFrameId === "Custom") {
        return { timeFrameId, start, end }
      } else if (this.shownYears.includes(timeFrameId)) {
        const start = boundedDate(convertYearToStartDate(timeFrameId))
        const end = boundedDate(convertYearToEndDate(timeFrameId))
        return { timeFrameId, start, end }
      } else {
        return defaultTimeFrame()
      }
    }

    const store = () => {
      storeCookieAndParam("timeFrame", this.timeFrameId)
      if (this.timeFrameId === "Custom") {
        storeCookieAndParam("startDate", format(this.start, "MM-dd-yyyy"))
        storeCookieAndParam("endDate", format(this.end, "MM-dd-yyyy"))
      } else {
        removeCookieAndParam("startDate")
        removeCookieAndParam("endDate")
      }
    }
    // ------------- End Private Methods -------------//

    // call the initialize method at the top of the constructor
    initialize()
  }
}
