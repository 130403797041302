import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import { titlecase } from "src/utils/string_helpers"

import { dateToLoad } from "./DateTimeInput"
import displayDuration from "./displayDuration"

const CruiseStopItem = ({ stop, previousStop, cruisePlanId }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: stop.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  }

  const editLink = (stop) => {
    const type = typeMatch(stop, {
      reservation: () => "reservation",
      marina: () => "marina",
      location: () => "location",
      note: () => "note",
    })
    return `/account/cruise_plans/${cruisePlanId}/cruise_stops/${type}_stops/${stop.id}/edit`
  }

  const typeMatch = (stop, { reservation, marina, location, note }) => {
    if (stop.type === "CruiseStop::ReservationStop") {
      return reservation(stop)
    }
    if (stop.type === "CruiseStop::MarinaStop") {
      return marina(stop)
    }
    if (stop.type === "CruiseStop::LocationStop") {
      return location(stop)
    }
    if (stop.type === "CruiseStop::NoteStop") {
      return note(stop)
    }
  }

  const iconClassNames = (stop) => {
    return typeMatch(stop, {
      reservation: () => "icon icon-calendar-check-o text-lg text-blue-500",
      marina: () => "icon icon-ship-store text-lg text-gray-600",
      location: () => "icon icon-md-location-on text-lg text-gray-600",
      note: () => "icon icon-document text-lg text-gray-600",
    })
  }

  const actions = (stop) => {
    return (
      <div className="flex w-full gap-4">
        <a href={editLink(stop)}>Edit</a>
        <a
          href={`/account/cruise_plans/${cruisePlanId}/cruise_stops/${stop.id}`}
          data-method="delete"
          data-confirm="Are you sure you want to delete this stop?"
        >
          Delete
        </a>
      </div>
    )
  }

  const renderPreviousStopDateErrors = () => {
    if (
      previousStop &&
      previousStop.endDate &&
      stop.startDate &&
      format(new Date(previousStop.endDate), "yyyy-MM-dd") !==
        format(new Date(stop.startDate), "yyyy-MM-dd")
    ) {
      return (
        <i
          className="icon icon-warning px-3 text-red-500"
          data-toggle="tooltip"
          data-placement="top"
          title="Possible date issue compared to previous stop end date"
        />
      )
    }
  }

  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <td className="shrink px-4">
        <i className={iconClassNames(stop)} />
      </td>
      <td className="px-4">
        {typeMatch(stop, {
          reservation: (stop) => stop.marina.name,
          marina: (stop) => stop.marina.name,
          location: (stop) => stop.name,
          note: (stop) => stop.title,
        })}
      </td>
      <td className="px-4">
        {typeMatch(stop, {
          reservation: (stop) => stop.latitude,
          marina: (stop) => stop.latitude,
          location: (stop) => stop.latitude,
          note: () => null,
        })}
      </td>
      <td className="px-4">
        {typeMatch(stop, {
          reservation: (stop) => stop.longitude,
          marina: (stop) => stop.longitude,
          location: (stop) => stop.longitude,
          note: () => null,
        })}
      </td>
      <td className="px-4">
        {stop.startDate && format(new Date(stop.startDate), "MMM dd, yyyy")}
        {renderPreviousStopDateErrors()}
      </td>
      <td className="px-4">
        {stop.endDate && format(new Date(stop.endDate), "MMM dd, yyyy")}
      </td>
      <td className="px-4">
        {stop.startDate &&
          format(dateToLoad(stop.startDate), "HH:mm") !== "00:00" &&
          format(dateToLoad(stop.startDate), "hh:mm a")}
      </td>
      <td className="px-4">
        {stop.endDate &&
          format(dateToLoad(stop.endDate), "HH:mm") !== "00:00" &&
          format(dateToLoad(stop.endDate), "hh:mm a")}
      </td>
      <td className="px-4">{stop.nauticalMiles}</td>
      <td className="px-4">{stop.knots}</td>
      <td className="px-4">{displayDuration(stop.duration)}</td>
      <td className="px-4">{stop.note}</td>
      <td className="px-4">
        {stop.reservation && (
          <span className="mr-4 rounded bg-blue-100 px-2 py-0.5 font-bold text-blue-900">
            {titlecase(stop.reservation.status)}
          </span>
        )}
      </td>
      <td className="px-4">{actions(stop)}</td>
    </tr>
  )
}

CruiseStopItem.propTypes = {
  stop: PropTypes.shape({
    id: PropTypes.string.isRequired,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    type: PropTypes.string.isRequired,
    name: PropTypes.string,
    note: PropTypes.string,
    marina: PropTypes.shape({
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    reservation: PropTypes.shape({
      status: PropTypes.string,
    }),
    nauticalMiles: PropTypes.string,
    knots: PropTypes.string,
    duration: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }).isRequired,
  previousStop: PropTypes.shape({
    endDate: PropTypes.string,
  }),
  cruisePlanId: PropTypes.string.isRequired,
}

export default CruiseStopItem
